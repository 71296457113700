<template>
  <div class="navbar bg-base-100">
    <div class="navbar-start" v-show="user">
      <div class="dropdown dropdown-start" @click="rightMenuOpen = true">
        <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar">
          <div class="justify-center items-center">
            <Avatar v-if="profile.avatarUrl" class="w-10 h-10" />
            <div
              v-else
              class="rounded-full w-10 h-10 text-xl bg-blue-100 flex justify-center items-center"
            >
              <span>{{ emailInitial }}</span>
            </div>
          </div>
        </div>
        <ul
          v-show="rightMenuOpen"
          tabindex="0"
          class="menu menu-md dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
        >
          <ClientOnly>
            <li class="px-3 pb-1">{{ user?.email?.split('@')[0] }}</li>
          </ClientOnly>
          <hr />
          <li>
            <NuxtLink to="/dashboard/settings" v-fix-dropdown-click
              >Ajustes personales</NuxtLink
            >
          </li>

          <!--<li><NuxtLink to="/settings">Billing</NuxtLink></li>-->
          <!--<li><NuxtLink to="/feedback">Give app feedback</NuxtLink></li>-->
          <li><a @click="signOut" v-fix-dropdown-click>Cerrar sesión</a></li>
        </ul>
      </div>
    </div>

    <div class="navbar-center">
      <NuxtLink :to="redirectNavLink" class="btn btn-ghost text-xl"
        >Artist Cable</NuxtLink
      >
    </div>

    <div class="navbar-end">
      <div
        class="dropdown dropdown-end"
        v-show="user"
        @click="leftMenuOpen = true"
      >
        <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block h-7 w-7 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </div>
        <ul
          v-show="leftMenuOpen"
          tabindex="0"
          class="menu menu-md dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <NuxtLink to="/dashboard/payment" v-fix-dropdown-click
              >Pagos</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/dashboard/profile" v-fix-dropdown-click
              >Ajustes de perfil público</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/dashboard/poll" v-fix-dropdown-click
              >Votación en vivo</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/dashboard/qr-code" v-fix-dropdown-click>
              Codigo QR</NuxtLink
            >
          </li>
          <li v-if="profile?.username" v-fix-dropdown-click>
            <NuxtLink
              :to="{
                path: `/${profile?.username}`,
                query: { impersonate: true },
              }"
            >
              Ir a perfil</NuxtLink
            >
          </li>
        </ul>
      </div>
    </div>

    <div v-show="!user" class="navbar-end">
      <NuxtLink to="/login">
        <button class="btn mr-2">Ingresar</button></NuxtLink
      >
      <!--<NuxtLink to="/sign-up"> <button class="btn">Sign up</button></NuxtLink>-->
    </div>
  </div>
</template>

<script setup>
import { navigateTo } from "nuxt/app";

const supabase = useSupabaseClient();
const user = useSupabaseUser();
const Sentry = useSentry();
const { $posthog } = useNuxtApp();
const rightMenuOpen = ref(false);
const leftMenuOpen = ref(false);
const profile = useProfileStore();
const redirectNavLink = computed(() =>
  user?.value ? "/dashboard/poll" : "/"
);
const donationsPolling = useDonationsPolling();
const emailInitial = computed(() => {
  return user?.value?.email?.[0].toUpperCase();
});

async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();

    if (error) {
      throw error;
    }
    navigateTo("/login");
    donationsPolling.disablePolling();

    $posthog().capture("sign_out");

    Sentry.setUser(null);
    $posthog.reset();
  } catch (error) {
    useNuxtApp().$toast.error("Ocurrió un error al cerrar sesión");
    navigateTo("/login");
  }
}

const route = useRoute();

watch(
  () => route.path,
  () => {
    leftMenuOpen.value = false;
    rightMenuOpen.value = false;
  }
);
</script>

<style></style>
