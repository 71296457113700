import { intToFloat } from "@/utils/currency";

export const useDonationsPolling = () => {
  const supabase = useSupabaseClient();
  const user = useSupabaseUser();
  const donationsStore = useDonationsStore();
  const runtimeConfig = useRuntimeConfig();

  const lastProcessedTimestamp = ref("");
  let pollInterval = null;
  const POLLING_INTERVAL = 5000;

  async function initializeLastProcessedTimestamp() {
    const now = new Date().toISOString();
    lastProcessedTimestamp.value = now;
  }

  async function fetchDonations() {
    if (!lastProcessedTimestamp.value) {
      return;
    }

    const { data, error } = await supabase
      .from("donation")
      .select("id, donor_name, total_amount, song_full_name, created_at")
      .gt("created_at", lastProcessedTimestamp.value)
      .order("created_at", { ascending: true })
      .eq("artist_profile_id", user.value?.id)
      .limit(30); // Limit the number of results for efficiency
      // TODO: paginate or fold

    if (error) {
      useNuxtApp().$toast.error("Ocurrió un error al obtener las donaciones");
    } else if (data && data.length > 0) {
      notifyUser(data);
      lastProcessedTimestamp.value = data[data.length - 1].created_at;
    }
  }

  function notifyUser(newDonations) {
    newDonations.forEach((donation) => {
      const message = `¡${donation?.donor_name || "Alguien"} donó $${intToFloat(
        donation.total_amount
      )} por "${donation.song_full_name}!"`;

      useNuxtApp().$toast.success(message, { autoClose: 10000 });
    });

    donationsStore.newDonation();
  }

  function startPolling() {
    pollInterval = setInterval(() => fetchDonations(), POLLING_INTERVAL);
  }

  // TODO: fix logout doesnt stop polling
  function stopPolling() {
    clearInterval(pollInterval);
    pollInterval = null;
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      stopPolling();
    } else {
      startPolling();
    }
  }

  async function enablePolling() {
    // Fix to avoid double polling while changing layouts. Improve this.
    if (pollInterval) {
      return;
    }
    
    if (runtimeConfig.public.ENV === "dev") {
      return;
    }

    await initializeLastProcessedTimestamp();
    startPolling();
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }

  function disablePolling() {
    stopPolling();
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  }

  onUnmounted(() => {
    // TODO: improve way to avoid having double polling
    disablePolling();
  });

  return {
    enablePolling,
    disablePolling,
  };
};
